import * as React from "react";
import {navigate} from "gatsby";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  Image,
  useDisclosure
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";

import Logo from "../images/logo.jpg";

export default function WithSubnavigation() {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("#131615", "#131615")}
        color={useColorModeValue("gray.600", "white")}
        minH="60px"
        py={{base: 2}}
        px={{base: 4}}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.900", "gray.900")}
        align="center"
      >
        <Flex
          flex={{base: 1, md: "auto"}}
          ml={{base: -2}}
          display={{base: "flex", md: "none"}}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex flex={{base: 1}} justify={{base: "center", md: "start"}}>
          <Box>
            <Image
              htmlWidth={200}
              htmlHeight={200}
              objectFit="cover"
              src={Logo}
              alt="Logo"
            />
          </Box>

          <Flex display={{base: "none", md: "flex"}} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");

  return (
    <Stack direction="row" spacing={4} alignItems="center">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Link
                p={2}
                onClick={navItem.onClick ?? null}
                href={navItem.href ?? "#"}
                fontSize="sm"
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};


const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{md: "none"}}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({label, children, href, onClick}) => {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
              href={href ?? "#"}
              onClick={onClick}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: "none"
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition="all .25s ease-in-out"
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{marginTop: "0!important"}}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align="start"
        >
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                py={2}
                href={child.href}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Home",
    onClick: () => {
      navigate("/");
    },
    href: "#"
  },
  {
    label: "About",
    onClick: () => {
      navigate("/about");
    }
  },
//   {
//     label: "Latest News"
//   },
  {
    label: "Contact",
    onClick: () => {
      navigate("/contact");
    },
    href: "#"
  }
];
