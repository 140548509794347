import {ChakraProvider} from "@chakra-ui/react";

/* eslint-disable no-prototype-builtins */
import React, {Fragment} from "react";
import Helmet from "react-helmet";
import {StaticQuery, graphql} from "gatsby";
import Nav from "./NavBar";
import Footer from "./Footer";
import Meta from "./Meta";
import "./globalStyles.css";

// import Nav from './Nav';
// import Footer from './Footer';

export default ({children, defaultSeo: pageSeo = null}) => {
  return (
    <StaticQuery
      query={graphql`
        query settings {
          directus {
            globalSettings {
              googleAnalyticsId
              defaultSeo {
                metaTitle
                metaDescription
                metaImage {
                  id
                }
              }
            }
          }
        }
      `}
          render={(data) => {
        const {defaultSeo, googleAnalyticsId} =
          data.directus.globalSettings || {};

        const seoData = pageSeo || defaultSeo;

        const seoImageUrl = seoData?.metaImage
          ? `https://mfp.clockworkbee.co.uk/assets/${seoData.metaImage.id}`
          : null;

        return (
          <>
            <Helmet
              defaultTitle={seoData.metaTitle}
              titleTemplate={`%s | ${seoData.metaTitle}`}
            >
              {seoData.metaTitle}
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Marck+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                rel="stylesheet"
              />

              {/* Add font link tags here */}
            </Helmet>
            <Meta
              googleTrackingId={googleAnalyticsId}
              absoluteImageUrl={seoImageUrl}
              description={seoData.metaDescription}
              title={seoData.metaTitle}
              siteTitle={seoData.metaTitle}
              siteDescription={seoData.metaDescription}
            />
            <ChakraProvider resetCSS>
              <Nav />
              {children}
              <Footer />
            </ChakraProvider>
          </>
        );
      }}
    />
  );
};
