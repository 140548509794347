import * as React from "react";

import {
  Box,
  chakra,
  Image,
  Container,
  //   Link,
  Stack,
  useColorModeValue,
  VisuallyHidden
} from "@chakra-ui/react";
import {FaFacebook} from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
// import {ReactNode} from "react";
import UkFire from "../images/uk_fire.png";
import Jewel from "../images/jewel.png";
import Chas from "../images/chas.svg";

const SocialButton = ({children, label, href, target}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded="full"
      w={8}
      h={8}
      cursor="pointer"
      as="a"
      href={href}
      target={target}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200")
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue("white", "white")}
      color={useColorModeValue("gray.800", "gray.800")}
    >
      <Container
        as={Stack}
        maxW="6xl"
        py={0}
        pb={2}
        pt={2}
        direction={{base: "column", md: "row"}}
        spacing={4}
        justify={{base: "center", md: "space-between"}}
        align={{base: "center", md: "center"}}
      >
        <a href="https://clockworkbee.co.uk" target="_blank" rel="noreferrer">
          © 2022 Clockwork Bee.
        </a>
        <Stack direction="row" spacing={6}>
          <Box>
            <Image
              htmlWidth={100}
              htmlHeight={200}
              objectFit="cover"
              src={UkFire}
              alt="Uk Fire"
            />
          </Box>
          <Box>
            <Image
              htmlWidth={80}
              htmlHeight={200}
              objectFit="cover"
              src={Jewel}
              alt="Jewel"
            />
          </Box>
          <Box>
            <Image
              htmlWidth={80}
              htmlHeight={200}
              objectFit="cover"
              src={Chas}
              alt="Chas"
            />
          </Box>
          <div style={{display: "flex", alignItems: "center"}}>
            <SocialButton
              label="Google"
              target="_blank"
              href="https://maps.app.goo.gl/2dNqSWgSTpADLTzP9"
            >
              <MdLocationOn />
            </SocialButton>
            <SocialButton
              label="Facebook"
              href="https://www.facebook.com/midlandfireprevention"
              target="_blank"
            >
              <FaFacebook />
            </SocialButton>
          </div>
        </Stack>
      </Container>
    </Box>
  );
}
