/* eslint-disable react/no-danger */
import * as React from "react";
import {Stack, VStack, useBreakpointValue} from "@chakra-ui/react";

export default function WithBackgroundImage({
  content,
  extraComponent = <span />,
  allowGradient = false
}) {
  const bgGradient = allowGradient ? "linear(to-r, blue.800, blue.900)" : null;
  return (
    <VStack
      w="full"
      justify="center"
      px={useBreakpointValue({base: 4, md: 8})}
      bgGradient={bgGradient}
    >
      <Stack maxW="6xl" align="flex-start" spacing={6}>
        {content}
      </Stack>
      {extraComponent}
    </VStack>
  );
}
