import * as React from "react";

import {
  Container,
  Stack,
  Flex,
  Box,
  Image,
  Icon,
  useColorModeValue
} from "@chakra-ui/react";

export default function CallToActionWithVideo(props) {
  let imageURL;
  if (!props.component) {
    imageURL = `https://mfp.clockworkbee.co.uk/assets/${props.featureImage.id}`;
  }
  const height = props?.height || 300;
  const extraComponent = props?.extraComponent || <span />;
  return (
    <Container maxW="8xl">
      <Stack
        align="center"
        spacing={{base: 8, md: 10}}
        py={{base: 20, md: 20}}
        direction={{base: "column", md: "row"}}
      >
        <Stack flex={1} spacing={{base: 5, md: 1}}>
          <div dangerouslySetInnerHTML={{__html: props.featureContent}} />
          {extraComponent}
        </Stack>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full"
        >
          <Blob
            w="150%"
            h="150%"
            position="absolute"
            top="-20%"
            left={0}
            zIndex={-1}
            color={useColorModeValue("blue.400", "blue.800")}
          />
          <Box
            position="relative"
            height={`${height}px`}
            rounded="2xl"
            boxShadow="2xl"
            width="full"
            overflow="hidden"
          >
            {props.component && <div>{props.component}</div>}
            {!props.component && (
              <Image
                alt="Hero Image"
                fit="cover"
                align="center"
                w="100%"
                h="100%"
                src={imageURL}
              />
            )}
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

export const Blob = (props) => {
  return (
    <Icon
      width="100%"
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
